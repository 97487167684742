import { useAuth } from 'src/Providers'

const AuthLoaderProvider = ({ children }) => {
  const { loading } = useAuth()

  return !loading || window.location.pathname.includes('app/login') ? (
    <>{children}</>
  ) : null
}

export default AuthLoaderProvider
