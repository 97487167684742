export const LEARNER_CATEGORY_FIELDS_FRAGMENT = gql`
  fragment LearnerCategoryFields on LearnerCategory {
    id
    name
    rank
    status
    createdAt
    updatedAt
    createdBy
    updatedBy
    clientId
    isGlobal
    isAll
    aclPrincipals {
      principalType
    }
    learnerCourses(isAdmin: true) {
      id
      name
      status
      rank
      learnerCategoryId
      learnerActivities {
        id
        name
        learnerTasks {
          id
          name
        }
      }
      associatedMilestones {
        id
        goalTitle
        attachedLearnerItemId
        parentGoalId
        isTemplate
      }
      learnerMilestonesPerClient @include(if: $isStafflinkUser) {
        learnerCourseId
        clientName
        totalMilestones
      }
    }
  }
`
