import { useEffect } from 'react'

import { create } from 'zustand'

import { CustomEventTypes, emitCustomEvent } from './useCustomEvent'

type RefreshClientDetail = {
  refreshClient: boolean
}

export const emitRefreshClient = (detail: RefreshClientDetail) => {
  emitCustomEvent({
    eventType: CustomEventTypes.REFRESH_CLIENT,
    detail,
  })
}

type RefreshClientStore = {
  refreshClient: boolean
  setRefreshClient: (value: boolean) => void
}

const useRefreshClientStore = create<RefreshClientStore>((set) => ({
  refreshClient: false,
  setRefreshClient: (value) => set({ refreshClient: value }),
}))

const eventListenerSetState = {
  value: false,
}

/**
 * Hook to listen for Refresh Client events
 *
 * Using a global state store so there is only one event listener
 */
const useRefreshClient = () => {
  const { refreshClient, setRefreshClient } = useRefreshClientStore()

  useEffect(() => {
    if (eventListenerSetState.value === false) {
      eventListenerSetState.value = true

      const eventHandler = (event: CustomEvent<RefreshClientDetail>) => {
        setRefreshClient(event.detail.refreshClient)
      }

      // Add the listener once globally, no need to remove in the unmount,
      // as there is always usage (mostly sidebar).
      document.addEventListener(
        CustomEventTypes.REFRESH_CLIENT,
        eventHandler,
        false,
      )
    }
  }, [])

  return { refreshClient }
}

export default useRefreshClient
