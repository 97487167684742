import React, { FC, ReactNode } from 'react'

import { isNil } from 'ramda'

import { Link, routes } from '@redwoodjs/router'

import useRefreshClient from 'src/lib/hooks/useRefreshClient'

export interface ChildNavigationItem {
  id: string
  cardId?: number
  sectionId?: number
  name: string
  href: string
  isEmbed?: boolean
  target?: string
  rel?: string
  beta?: boolean
}

export interface NavigationItem extends ChildNavigationItem {
  customIcon?: ReactNode
  icon: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string
      titleId?: string
    } & React.RefAttributes<SVGSVGElement>
  >
  path?: string
  children?: ChildNavigationItem[]
}

interface SidebarLinkProps {
  className: string
  navItem: NavigationItem | ChildNavigationItem
  children: ReactNode
  setSidebarOpen?: (open: boolean) => void
  setIntercomOpen?: (open: boolean) => void
}

const MENU_ITEM_SELECTED_CLASS = 'sidebar-item-current'

const matchNavItemPath = (navItemHref: string) =>
  navItemHref === location?.pathname ||
  (navItemHref !== '/app' && location?.pathname?.includes(navItemHref))

const navItemExtraClass = (navItemHref: string) => {
  return matchNavItemPath(navItemHref) ? ' ' + MENU_ITEM_SELECTED_CLASS : ''
}

const SidebarLink: FC<SidebarLinkProps> = ({
  navItem,
  className,
  children,
  setSidebarOpen,
  setIntercomOpen,
}) => {
  const { refreshClient } = useRefreshClient()

  const externalLink = navItem.target === '_blank'
  const isLink = !isNil(navItem?.href) && navItem.href !== '#'

  if (navItem.isEmbed) {
    return (
      <Link
        id={navItem.id}
        data-testid={navItem.id}
        className={
          className +
          navItemExtraClass(
            '/app/embed/' + navItem.sectionId + '/' + navItem.cardId,
          )
        }
        to={routes.embed({
          sectionId: navItem.sectionId ?? 0,
          cardId: navItem.cardId ?? 0,
        })}
        onClick={() => setSidebarOpen?.(false)}
      >
        {children}
      </Link>
    )
  }

  const useAnchor = refreshClient || externalLink || !isLink

  return useAnchor ? (
    <a
      id={navItem.id}
      data-testid={navItem.id}
      className={className + navItemExtraClass(navItem.href)}
      {...(isLink && {
        rel: navItem.target,
        href: navItem.href,
        target: navItem.target,
      })}
    >
      {children}
    </a>
  ) : (
    <Link
      id={navItem.id}
      data-testid={navItem.id}
      className={className + navItemExtraClass(navItem.href)}
      to={navItem.href}
      onClick={() => {
        setSidebarOpen?.(false)
        setIntercomOpen?.(false)
      }}
    >
      {children}
    </Link>
  )
}

export default SidebarLink
