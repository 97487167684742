import { FC } from 'react'

const TheHubIcon: FC<{
  variant?: 'white' | 'default'
  size?: 'xsmall' | 'small'
}> = ({ variant = 'default', size = null }) => {
  const wordColor = variant === 'white' ? '#ffffff' : '#112A34'
  const iconColor = variant === 'white' ? '#ffffff' : '#038EFD'

  let width

  switch (size) {
    case 'xsmall': {
      width = '150'
      break
    }
    case 'small': {
      width = '250'
      break
    }
    default: {
      width = '100%'
      break
    }
  }

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4273.59 1295.79"
      width={width}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M624.368 0L564.646 104.966L179.167 769.149H564.646V874.115H0L282.323 385.479L503.114 0H624.368ZM479.587 1295.79H600.841L823.442 910.311L1105.77 421.675H539.309V526.641H924.789L539.309 1192.63L479.587 1295.79Z"
        fill={iconColor}
      ></path>
      <path
        d="M1346.91 419.764H1596.65V504.861H1513.4V872.999H1426.45V504.861H1346.91V419.764Z"
        fill={wordColor}
      ></path>
      <path
        d="M1781.62 419.764H1868.57V589.958H2020.27V419.764H2107.21V872.999H2020.27V673.206H1868.57V872.999H1781.62V419.764Z"
        fill={wordColor}
      ></path>
      <path
        d="M2336.6 419.764H2584.5V503.011H2423.55V584.408H2584.5V667.656H2423.55V786.052H2584.5V872.999H2336.6V419.764Z"
        fill={wordColor}
      ></path>
      <path
        d="M3145.55 420V869.832H3101.86V659.488H2835.32V869.832H2791V420H2835.32V620.207H3101.86V420H3145.55Z"
        fill={wordColor}
      ></path>
      <path
        d="M3535.8 873C3500.35 873 3470.38 866.664 3445.9 853.993C3421.42 841.322 3402 824.427 3387.65 803.308C3373.3 781.766 3362.75 757.48 3356 730.448C3349.67 703.415 3346.5 675.538 3346.5 646.817V420H3390.19V646.817C3390.19 670.048 3392.51 692.856 3397.15 715.242C3402.21 737.628 3410.23 757.691 3421.21 775.431C3432.18 793.171 3446.95 807.32 3465.53 817.88C3484.1 828.017 3507.31 833.085 3535.17 833.085C3563.87 833.085 3587.51 827.806 3606.08 817.246C3625.07 806.264 3639.84 791.903 3650.4 774.164C3661.37 756.424 3669.18 736.572 3673.82 714.608C3678.46 692.222 3680.79 669.625 3680.79 646.817V420H3725.1V646.817C3725.1 676.806 3721.73 705.527 3714.97 732.982C3708.22 760.436 3697.25 784.512 3682.05 805.208C3667.28 825.905 3647.86 842.378 3623.81 854.627C3599.75 866.876 3570.41 873 3535.8 873Z"
        fill={wordColor}
      ></path>
      <path
        d="M4273.59 753.889C4273.59 776.275 4267.89 796.338 4256.49 814.078C4245.09 831.396 4229.69 845.123 4210.27 855.26C4191.28 864.975 4170.39 869.832 4147.59 869.832H3935.5V420H4152.03C4173.13 420 4191.28 425.702 4206.47 437.106C4222.09 448.088 4233.91 462.449 4241.93 480.189C4250.37 497.506 4254.59 515.669 4254.59 534.676C4254.59 557.062 4248.89 577.758 4237.5 596.765C4226.1 615.772 4210.27 629.922 4190.01 639.214C4215.76 646.817 4236.02 660.966 4250.79 681.663C4265.99 702.359 4273.59 726.435 4273.59 753.889ZM4229.27 746.287C4229.27 731.081 4225.89 717.143 4219.14 704.471C4212.38 691.378 4203.1 680.818 4191.28 672.793C4179.88 664.768 4166.8 660.755 4152.03 660.755H3979.82V831.185H4147.59C4162.79 831.185 4176.51 827.172 4188.75 819.147C4200.99 811.122 4210.7 800.773 4217.87 788.102C4225.47 775.008 4229.27 761.07 4229.27 746.287ZM3979.82 458.648V624.642H4134.3C4149.07 624.642 4162.16 620.841 4173.55 613.238C4184.95 605.213 4194.02 594.864 4200.78 582.193C4207.53 569.522 4210.91 556.006 4210.91 541.645C4210.91 526.439 4207.74 512.712 4201.41 500.463C4195.08 487.792 4186.43 477.655 4175.45 470.052C4164.48 462.449 4152.03 458.648 4138.1 458.648H3979.82Z"
        fill={wordColor}
      ></path>
    </svg>
  )
}

export default TheHubIcon
