import { Tooltip } from '@mui/material'

import useRefreshClient from 'src/lib/hooks/useRefreshClient'

import Button from '../Library/Button'

const RefreshClient = () => {
  const { refreshClient } = useRefreshClient()

  return (
    <>
      {refreshClient && (
        <div key="refreshClient">
          <Tooltip title="A new version of The Hub is Available. Click here to reload the page!">
            <div>
              <Button
                buttonDataTestId="sidebar-refresh-client-btn"
                onClick={() => window.location.reload()}
                className="min-w-[0px] !rounded-none px-0 text-xs"
              >
                New Version Available!
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  )
}

export default RefreshClient
