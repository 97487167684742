export const ACL_FIELDS_FRAGMENT = gql`
  fragment ACLFields on ACL {
    id
    updatedAt
    updatedBy
    clientId
    createdAt
    createdBy
    principalId
    principalType
    resourceId
  }
`
