import React, { FC } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { ConfirmOptions } from './types'

interface Props {
  open: boolean
  options: ConfirmOptions
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

const ConfirmationDialog: FC<Props> = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    hideCancelButton,
  } = options

  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={open}
      onClose={allowClose ? onClose : null}
    >
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>{description}</DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions>
        {!hideCancelButton && (
          <Button
            className="text-gray-400 hover:bg-gray-200"
            {...cancellationButtonProps}
            onClick={onCancel}
          >
            {cancellationText}
          </Button>
        )}
        <Button
          className="bg-indigo-600 hover:bg-indigo-500"
          variant={'contained'}
          {...confirmationButtonProps}
          onClick={onConfirm}
          disableElevation
        >
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
