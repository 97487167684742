import { CircularProgress, CircularProgressProps } from '@mui/material'

const Loading = (props?: {
  circularProgressProps?: CircularProgressProps
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  size?: 'small' | 'x-small'
}) => {
  let sizeClasses = '!w-16 !h-16'

  if (props.size === 'small') sizeClasses = '!w-6 !h-6'
  if (props.size === 'x-small') sizeClasses = '!w-4 !h-4'

  return (
    <div
      {...props?.containerProps}
      className={`m-auto grid h-full w-full grid-cols-1 place-items-center text-indigo-600 ${props?.containerProps?.className ?? ''}`}
      data-testid="loading-component"
    >
      <CircularProgress
        className={sizeClasses}
        color={props?.circularProgressProps?.color ?? 'inherit'}
        {...props?.circularProgressProps}
      />
    </div>
  )
}

export default Loading
