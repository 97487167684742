import { useState } from 'react'

import { PROD_CLIENT } from 'api/src/common/enums'
import { sortWith, ascend, prop } from 'ramda'

import { useAuth } from 'src/Providers'

import AliasHeader from './AliasHeader'
import AliasItem from './AliasItem'
import { SuccessProps, Client } from './AliasSwitcherCell'

const getSortedClientListWithActiveFirst = (
  currentClientId: number,
  clientList: Client[],
  searchString: string,
) => {
  const filteredClients = clientList.filter(
    (client) =>
      client.id !== currentClientId &&
      client.id !== PROD_CLIENT.STAFFLINK &&
      client.name.toLowerCase().includes(searchString.toLowerCase()),
  )

  const sortedClients = sortWith([ascend(prop('name'))], filteredClients)

  const activeClient = clientList.find(
    (client) =>
      client.id !== PROD_CLIENT.STAFFLINK && client.id === currentClientId,
  )

  return activeClient ? [activeClient, ...sortedClients] : sortedClients
}

const AliasSwitcher = (props: SuccessProps) => {
  const { currentUser } = useAuth()
  const { clientList, selectAlias } = props

  const getFilteredClientList = (searchString: string) =>
    getSortedClientListWithActiveFirst(
      currentUser.membershipData.clientId,
      clientList,
      searchString,
    )

  const [filteredClientList, setFilteredClientList] = useState(
    getFilteredClientList(''),
  )

  const handleSearchChange = (searchString: string) => {
    const list = getFilteredClientList(searchString)
    setFilteredClientList(list)
  }

  return (
    <div className={'h-[650px] overflow-y-scroll bg-white px-4 relative'}>
      <AliasHeader {...props} handleSearchChange={handleSearchChange} />

      <div className="my-4">
        <ul className="flex-col flex gap-2">
          {filteredClientList.map((client) => (
            <AliasItem
              key={client.id}
              client={client}
              selectAlias={selectAlias}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AliasSwitcher
