import StatuspageLink from 'src/components/StatuspageLink/StatuspageLink'

export const QUERY = gql`
  query FindStatuspageLinkQuery {
    statuspageStatus: getStatuspageStatus {
      indicator
    }
  }
`

export const Loading = () => <StatuspageLink indicator="loading" />

export const Empty = () => <StatuspageLink indicator="empty" />

export const Failure = () => <StatuspageLink indicator="failure" />

export const Success = ({ statuspageStatus }) => {
  return <StatuspageLink indicator={statuspageStatus.indicator} />
}
