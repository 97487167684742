export const IMPROVER_GOAL_MEMBER_FIELDS_FRAGMENT = gql`
  fragment ImproverGoalMemberFields on ImproverGoalMember {
    id
    clientId
    createdBy
    updatedBy
    createdAt
    updatedAt
    createdByUserName
    updatedByUserName
    goalTitle
    goalBody
    isComplete
    dueDate
    startDate
    startValue
    currentValue
    targetValue
    status
    parentGoalId
    isTemplate
    isGlobal
    templateType
    startDateNumber
    startDateFormat
    startDateTrigger
    startDateTriggerMilestoneId
    dueDateNumber
    dueDateFormat
    dueDateTrigger
    dueDateTriggerMilestoneId
    percentProgress
    diffDays
    currentStatus
    attachedLearnerItemId
    completedDate
    isPrivate
    templateUserRoles
  }
`
