/// <reference types="intercom-web" />
import { useEffect } from 'react'

import useMediaQuery from '@mui/material/useMediaQuery'
import { E2E_USERS } from 'api/src/common/constants'
import { PROD_CLIENT } from 'api/src/common/enums'
import { useLocalStorage } from 'usehooks-ts'

import { useAuth } from 'web/src/Providers'

const chatId = process.env.FRONT_THE_HUB_CHAT_ID
const apiBase = process.env.INTERCOM_API_BASE

export const useIntercomChat = () => {
  const { currentUser } = useAuth()

  const userEmail = currentUser?.userData?.email
  const userHash = currentUser?.frontUserHash
  const userName = currentUser?.userData?.name
  const membershipRole = currentUser?.membershipData?.role
  const clientMFASetting = currentUser?.parentData?.mfaSetting
  const baserowUserId = currentUser?.userData?.baserowUserId
  const isBaserowUser = baserowUserId ? true : false
  const isMobile = useMediaQuery('(max-width: 640px)')

  let isStafflinkInternal: boolean

  const memberships = currentUser?.userData?.memberships

  if (!memberships) {
    isStafflinkInternal = false
  } else {
    isStafflinkInternal = memberships.some(
      (membership) => membership.client.id === PROD_CLIENT.STAFFLINK,
    )
  }

  const [lastLookupSSOClient] = useLocalStorage('lastLookupSSOClient', null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const directTicketSubmission = urlParams.get('submitTicket')

    if (chatId && userHash && window.Intercom) {
      // handle case where user IS logged in

      const cognitoUserStatus = currentUser?.userData?.cognitoUserStatus
      const cognitoHasMFAEnabled = currentUser?.userData?.cognitoHasMFAEnabled
      const SSO = cognitoUserStatus === 'EXTERNAL_PROVIDER'
      // intercom messages can mess with e2es, so we hide the launcher
      const isE2ETester = Object.values(E2E_USERS).includes(userEmail)

      window.Intercom('boot', {
        app_id: chatId,
        api_base: apiBase,
        email: userEmail,
        user_hash: userHash,
        name: userName,
        cognitoUserStatus,
        cognitoHasMFAEnabled,
        SSO,
        membershipRole,
        clientMFASetting,
        SSO_available: lastLookupSSOClient ? true : false,
        isBaserowUser,
        logged_in: true,
        isStafflinkInternal,
        // when was the last time we recorded the user in a login state?
        login_recorded_at: new Date().toISOString(),
      })

      if (isMobile) {
        window.Intercom('update', {
          hide_default_launcher: true,
        })
      }

      if (isE2ETester) {
        window.Intercom('shutdown')
      }

      if (directTicketSubmission === 'true') {
        setTimeout(() => {
          window.Intercom('show')
          window.Intercom('showNewMessage')
        }, 2000)
      }
    }
  }, [userEmail, userHash, isMobile])

  useEffect(() => {
    if (!userHash && chatId && window.Intercom) {
      // handle case where user is NOT logged in
      window.Intercom('boot', {
        app_id: chatId,
        api_base: apiBase,
        logged_in: false,
        // when was the last time we recorded the user in a logout state?
        logout_recorded_at: new Date().toISOString(),
      })
    }
  })
}
