import React from 'react'

import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import { Button as MuiButton, createTheme, ThemeProvider } from '@mui/material'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    error: {
      main: '#ef4444',
    },
    info: {
      main: 'rgba(0,0,0,0)',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#facc15',
      contrastText: '#1f2937',
    },
  },
})

export interface CustomButtonProps extends LoadingButtonProps {
  elevated?: boolean
  enableRipple?: boolean
  className?: string
  slider?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
  buttonDataTestId?: string
  loadingPosition?: 'start' | 'end' | 'center'
  variant?: 'contained' | 'outlined' | 'text'
}

const Button: React.FC<CustomButtonProps> = ({
  disabled,
  endIcon,
  startIcon,
  variant = 'contained',
  onClick,
  children,
  elevated = false,
  className,
  href,
  color = 'primary',
  enableRipple = false,
  fullWidth = true,
  slider = false,
  loading = false,
  type,
  buttonDataTestId,
  loadingPosition,
  ...rest
}) => {
  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <LoadingButton
          loadingPosition={loadingPosition}
          loading={loading}
          type={type}
          color={color}
          disableRipple={!enableRipple}
          disabled={disabled}
          endIcon={endIcon}
          startIcon={startIcon}
          fullWidth={fullWidth}
          variant={variant}
          onClick={onClick}
          href={href}
          disableElevation={!elevated}
          style={{
            backgroundColor: color,
          }}
          className={`${className} capitalize ${
            variant === 'outlined' && 'hover:bg-indigo-600 hover:text-white'
          } ${variant === 'text' && color !== 'error' && 'hover:bg-gray-200'}`}
          {...rest}
        >
          {children}
        </LoadingButton>
      ) : (
        <>
          {slider ? (
            <>
              <MuiButton
                data-testid={buttonDataTestId}
                data-intercom-target={buttonDataTestId}
                color={color}
                disableRipple={!enableRipple}
                disabled={disabled}
                variant={variant}
                onClick={onClick}
                href={href}
                disableElevation={!elevated}
                className={`${className} group absolute right-2 capitalize ${
                  variant === 'outlined' &&
                  color !== 'error' &&
                  'hover:bg-indigo-600 hover:text-white'
                } ${variant === 'text' && 'hover:bg-gray-200'}`}
                {...rest}
              >
                {startIcon}
                <span className="mt-0.5 max-w-0 overflow-hidden transition-all duration-1000 group-hover:max-w-[90px] group-focus:max-w-[90px]">
                  {children}
                </span>
              </MuiButton>
            </>
          ) : (
            <MuiButton
              data-testid={buttonDataTestId}
              data-intercom-target={buttonDataTestId}
              type={type}
              color={color}
              disableRipple={!enableRipple}
              disabled={!disabled ? false : true}
              endIcon={endIcon}
              startIcon={startIcon}
              fullWidth={fullWidth}
              variant={variant}
              onClick={onClick}
              href={href}
              disableElevation={!elevated}
              className={`${className} capitalize leading-6 ${
                variant === 'outlined' &&
                color === undefined &&
                'hover:bg-indigo-600 hover:text-white'
              } ${
                variant === 'text' && color !== 'error' && 'hover:bg-gray-200'
              }`}
              {...rest}
            >
              {children}
            </MuiButton>
          )}
        </>
      )}
    </ThemeProvider>
  )
}

export default Button
