import { useEffect, useState } from 'react'

export enum CustomEventTypes {
  REFRESH_CLIENT = 'REFRESH_CLIENT',
}

/**
 * Emit a custom event which will be picked up by the useCustomEvents hook
 */
export const emitCustomEvent = <T>(params: {
  eventType: CustomEventTypes
  detail: T
}) => {
  const { eventType, detail } = params
  const customEvent = new CustomEvent(eventType, {
    detail,
  })

  document.dispatchEvent(customEvent)
}

/**
 * Hook to listen for custom events
 *
 * Allows re-rendering specific components without using context
 * useContext requires a useState, which triggers a re-render of the component and all the children
 *
 * NOTE: If required for the same event > 10 times, write a custom hook like: web/src/lib/hooks/useRefreshClient.ts
 *  * This will prevent adding the same event listener multiple times
 */
const useCustomEvents = <T>(
  eventType: CustomEventTypes,
  params: { initialValue?: T },
) => {
  const { initialValue } = params
  const [detail, setDetail] = useState(initialValue)

  useEffect(() => {
    const eventHandler = (event: CustomEvent<T>) => {
      setDetail(event.detail)
    }

    document.addEventListener(eventType, eventHandler, false)

    return () => {
      document.removeEventListener(eventType, eventHandler)
    }
  }, [])

  return detail
}

export default useCustomEvents
