export enum LessonType {
  none,
  video,
  quiz,
  text,
  videoWithText,
  processMap,
  embed,
  embedWithText,
  unknown,
}

export enum LessonViews {
  settings,
  chapter,
  edit,
  new,
  none,
}

export enum LocalStorageKeys {
  USER_CLIENT_ID = 'user-client-id',
}
