import { useContext } from 'react'

import LiveFeatureContext from 'src/lib/hooks/LiveFeatureFlag/LiveFeatureContext'

const useLiveFeature = (feature: string) => {
  const { flags } = useContext(LiveFeatureContext)
  const flag = flags.get(feature)
  if (!flag) {
    return [false]
  }
  const result: boolean = flags.get(feature)[0]
  return [result]
}

export default useLiveFeature
