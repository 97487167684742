import { FC } from 'react'

import { captureException } from '@sentry/browser'
import {
  AliasSwitcherClients,
  AliasSwitcherClientsVariables,
} from 'types/graphql'

import {
  CellFailureProps,
  CellLoadingProps,
  CellSuccessProps,
} from '@redwoodjs/web'

import LoadingComponent from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'

import AliasSwitcher from './AliasSwitcher'

type ClientList = AliasSwitcherClients['clientList']
export type Client = ClientList[0]
export const QUERY = gql`
  query AliasSwitcherClients {
    clientList: clients {
      id
      name
      logoUrl
      status
    }
  }
`

type DefaultProps = {
  closeAliasSwitcher: () => void
  selectAlias: (clientId: number) => void
}

type LoadingProps = CellLoadingProps<AliasSwitcherClientsVariables> &
  DefaultProps

export const Loading: FC<LoadingProps> = ({ closeAliasSwitcher }) => {
  return (
    <Modal open={true} onClose={closeAliasSwitcher}>
      <div className="h-[500px] overflow-y-scroll bg-gray-50 px-4">
        <div className="sticky top-0 bg-gray-50 pb-2 mt-2 w-full">
          <h1 className="mb-2 text-xl text-gray-600">Enter Support Mode</h1>
          <div className="h-[400px]">
            <LoadingComponent />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => {
  captureException(error)
  return (
    <div style={{ color: 'red' }}>Something went wrong, please try again.</div>
  )
}

export type SuccessProps = CellSuccessProps<
  AliasSwitcherClients,
  AliasSwitcherClientsVariables
> &
  DefaultProps

export const Success: FC<SuccessProps> = ({
  clientList,
  closeAliasSwitcher,
  selectAlias,
}) => {
  return (
    <Modal open={true} onClose={closeAliasSwitcher}>
      <AliasSwitcher
        clientList={clientList}
        closeAliasSwitcher={closeAliasSwitcher}
        selectAlias={selectAlias}
      />
    </Modal>
  )
}
