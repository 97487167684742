import { useState, useEffect } from 'react'

import { TrackEventFunction } from './useAnalytics'

const useScrollTracker = (trackEvent: TrackEventFunction): void => {
  const [scrollThreshold, setScrollThreshold] = useState({
    top: false,
    middle: false,
    bottom: false,
  })

  useEffect(() => {
    const onScroll = () => {
      const windowHeight = window.innerHeight,
        documentHeight = document.documentElement.scrollHeight,
        scrollTop = document.documentElement.scrollTop
      const maxScroll = documentHeight - windowHeight,
        topThird = maxScroll / 3,
        middleThird = (maxScroll * 2) / 3

      const updateThreshold = (
        newState: { top: boolean; middle: boolean; bottom: boolean },
        scrollPosition: string,
      ) => {
        setScrollThreshold(newState)
        trackEvent('Pages', 'Scroll', { scrollPosition })
      }

      if (!scrollThreshold.top && scrollTop <= topThird)
        updateThreshold({ top: true, middle: false, bottom: false }, 'top')
      else if (
        !scrollThreshold.middle &&
        scrollTop > topThird &&
        scrollTop <= middleThird
      )
        updateThreshold({ top: false, middle: true, bottom: false }, 'middle')
      else if (
        !scrollThreshold.bottom &&
        scrollTop > middleThird &&
        scrollTop <= maxScroll
      )
        updateThreshold({ top: false, middle: false, bottom: true }, 'bottom')
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollThreshold])
}

export default useScrollTracker
