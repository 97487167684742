import { useAuth } from 'src/Providers'

import { Client } from '../AliasSwitcherCell/AliasSwitcherCell'
import Button from '../Library/Button/Button'

interface AliasItemProps {
  client: Client
  selectAlias: (clientId: number) => void
}

const AliasItem = (props: AliasItemProps) => {
  const { currentUser } = useAuth()
  const { client, selectAlias } = props

  return (
    <li key={client.id}>
      <Button
        className="w-full text-left flex items-center justify-between bg-gray-800 hover:bg-gray-600 focus:bg-gray-600 !border-gray-800"
        buttonDataTestId={`alias-switcher-${client.name.replace(' ', '-')}`}
        onClick={() => selectAlias(client.id)}
        variant="outlined"
      >
        <span>
          {client.id === currentUser.membershipData.clientId ? (
            <div className="flex flex-row gap-x-2 items-end">
              <h1 className="text-white text-2xl">{client.name}</h1>
              <span className="text-green-500 text-lg">Support Mode</span>
            </div>
          ) : (
            <h1 className="text-white text-2xl">{client.name}</h1>
          )}

          {client.status === 'INACTIVE' && (
            <span className="text-red-500 text-lg normal-case">Inactive</span>
          )}

          {client.status === 'PAUSED' && (
            <span className="text-orange-400 text-lg normal-case">Paused</span>
          )}

          {client.status === 'SUSPENDED' && (
            <span className="text-red-500 text-lg normal-case">Suspended</span>
          )}
        </span>

        <img
          className="h-[50px]"
          height="50"
          width="auto"
          alt={client?.name}
          src={client.logoUrl}
        />
      </Button>
    </li>
  )
}

export default AliasItem
