import React from 'react'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { isDateAfterBillingSwapOver } from 'api/src/common/utils'
import dayjs from 'dayjs'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import Button from 'src/components/Library/Button'
import { useAuth } from 'src/Providers'

const ClientBillingSetupDialog = () => {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const handleCheck = () => {
    const createdAfterCutOff = isDateAfterBillingSwapOver(
      dayjs(currentUser.parentData.createdAt),
    )

    const isSupportMode = currentUser.isClientAlias

    const showDialog = !!(
      (currentUser.roles.includes('OWNER') ||
        currentUser.roles.includes('ADMIN')) &&
      !currentUser.parentData.stripeSetup &&
      createdAfterCutOff &&
      !isSupportMode
    )

    window.Intercom('update', {
      billing_dialog_shown: showDialog,
    })

    return showDialog
  }

  const showDialog = handleCheck()
  // check path is not within billing
  if (pathname.includes('billing')) return

  return (
    <Dialog
      fullScreen={false}
      open={showDialog}
      onClose={() => {}}
      maxWidth="lg"
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          m: 'auto',
          width: 'fit-content',
          alignItems: 'center',
          padding: '2rem',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="text-center font-bold capitalize"
        >
          👋 Welcome to The Hub!
        </DialogTitle>
        <DialogContent className="text-center">
          <DialogContentText className="whitespace-pre-line text-center">
            Before you can start using The Hub, you need to setup your billing
            details. Click continue to setup your billing details.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            width: '300px',
          }}
        >
          <Button
            onClick={() => {
              navigate(
                routes.settingsClientBilling({
                  product: currentUser.parentData.stripeDefaultProductId,
                }),
              )
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
export default ClientBillingSetupDialog
