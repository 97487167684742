import { InMemoryCache } from '@apollo/client'

import { fragmentRegistry as fragments } from './fragmentRegistry/fragmentRegistry'
import { typePolicies } from './typePolicy/typePolicies'

// Helpful information when working with the apollo cache:
// 1) https://www.apollographql.com/docs/react/development-testing/developer-tooling/#apollo-client-devtools
// 2) run this command in your browser console to log out the apollo cache -> __APOLLO_CLIENT__.cache.data.data

export const apolloCache = new InMemoryCache({
  typePolicies,
  fragments,
})
