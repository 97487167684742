import React, { ReactNode, useMemo, useState } from 'react'

import LiveFeatureContext from 'src/lib/hooks/LiveFeatureFlag/LiveFeatureContext'
import { useAuth } from 'src/Providers'

interface Props {
  children?: ReactNode
}

const LiveFeatureProvider: React.FC<Props> = ({ children }) => {
  const [flags, setFlags] = useState<Map<string, [boolean]>>(new Map())

  const { currentUser } = useAuth()

  useMemo(() => {
    if (currentUser) {
      const featuresForClient = currentUser.featuresForClient
      setFlags(
        Object.keys(featuresForClient).reduce((acc, key) => {
          acc.set(featuresForClient[key].name, [featuresForClient[key].enabled])
          return acc
        }, new Map<string, [boolean]>()),
      )
    }
  }, [currentUser])

  return (
    <>
      <LiveFeatureContext.Provider value={{ flags }}>
        {children}
      </LiveFeatureContext.Provider>
    </>
  )
}

export default LiveFeatureProvider
