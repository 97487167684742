import { FC } from 'react'

import Button from '@mui/material/Button'
import { captureException } from '@sentry/browser'
import {
  UpdateUserSidebarMutation,
  UpdateUserSidebarMutationVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Modal from 'src/components/Modal/Modal'
import { useAuth } from 'src/Providers'

interface MembershipSwitchProps {
  isModalOpen: boolean
  closeModal(): void
}

const UPDATE_USER = gql`
  mutation UpdateUserSidebarMutation($input: UpdateUserInput!, $id: Int!) {
    updateUser(input: $input, id: $id) {
      activeMembershipId
    }
  }
`

const MembershipSwitch: FC<MembershipSwitchProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const { currentUser } = useAuth()

  const [updateUserMutation] = useMutation<
    UpdateUserSidebarMutation,
    UpdateUserSidebarMutationVariables
  >(UPDATE_USER, {
    onCompleted: () => {
      closeModal()
      navigate(routes.home())
      location.reload()
    },
    onError: (error) => {
      toast.error(error.message)
      captureException(error)
    },
  })

  const selectMembership = (membershipId: number) => {
    if (
      currentUser.userData.memberships
        .map((membership) => membership.id)
        .includes(membershipId) &&
      currentUser.userData.activeMembershipId !== membershipId
    ) {
      updateUserMutation({
        variables: {
          id: currentUser.userData.id,
          input: {
            activeMembershipId: membershipId,
          },
        },
      })
    }
  }
  return (
    <Modal open={isModalOpen} onClose={closeModal} dialogClassName="p-4">
      <h1 style={{ marginBottom: '20px' }}>
        {'Swap to another client membership?'}
      </h1>
      <ul className="flex flex-col gap-4">
        {currentUser.userData.memberships.map((membership) => (
          <li key={membership.id}>
            <Button
              variant="text"
              className="w-full text-left flex items-center justify-between bg-gray-800 hover:bg-gray-600 focus:bg-gray-600 !border-gray-800"
              onClick={() => selectMembership(membership.id)}
            >
              <span className="flex flex-col justify-center">
                <h1 className="text-2xl text-white">
                  {membership.client.name}
                </h1>
                {membership.id === currentUser.userData.activeMembershipId && (
                  <span className="text-sm text-green-500">Active</span>
                )}
              </span>

              <img
                alt={membership?.client?.name}
                className="h-14"
                src={membership.client.logoUrl}
              />
            </Button>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default MembershipSwitch
